// ==============================|| OVERRIDES - TABLE ROW ||============================== //

import { Theme } from '@mui/material/styles';

export default function TableRow(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-of-type': {
            '& .MuiTableCell-root': {
              borderBottom: 'none'
            }
          },
          '&.assets': {
            backgroundColor: theme.palette.background.default,
            '& .MuiTableCell-root': {
              '&:last-of-type': {
                paddingRight: 0
              },
              '&:first-of-type': {
                paddingLeft: 0
              }
            }
          },
          '& .MuiTableCell-root': {
            '&:last-of-type': {
              paddingRight: 24
            },
            '&:first-of-type': {
              paddingLeft: 24
            }
          },
          '&.assets-tbody': {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid rgb(120 122 145 / 30%)',
            '& .MuiTableCell-body': {
              padding: '10px 5px',
              borderBottom: '1px solid rgb(120 122 145 / 30%)',
              '&:first-of-type': {
                paddingLeft: 24
              }
            }
          },
          '&.pagination': {
            backgroundColor: theme.palette.background.default
          },
          '&.bg-dim': {
            backgroundColor: theme.palette.grey[200],
            '&:hover': {
              backgroundColor: theme.palette.grey[200]
            }
          }
        }
      }
    }
  };
}
