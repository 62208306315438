import { useQuery } from 'react-query';
import { fetchUserSettings, QUERY_KEY_USER_SETTINGS } from '../query/users';

const useUserSettings = () => {
  const { data } = useQuery([QUERY_KEY_USER_SETTINGS], fetchUserSettings);

  if (data) {
    return data;
  }

  return null;
};

export default useUserSettings;
