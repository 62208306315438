// ==============================|| OVERRIDES - TABS ||============================== //

import { Theme } from '@mui/material/styles';

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          overflow: 'inherit',
          borderBottom: `1px solid ${theme.palette.grey['500']}`
        },
        indicator: {
          bottom: '-2px',
          height: '3px'
        },
        scroller: {
          overflow: 'inherit'
        },
        vertical: {
          overflow: 'visible'
        }
      }
    }
  };
}
