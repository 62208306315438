import axios from 'axios';

const axiosServices = axios.create();

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

const refreshToken = async () => {
  const token = window.localStorage.getItem('refreshToken');
  const response = await axiosServices.post('/api/token/refresh/', { refresh: token });
  return response.data['access'];
};

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (401 === error.response.status) {
      if (originalRequest.url === '/api/token/refresh/') {
        window.location.href = '/login';
      }

      if (originalRequest.url === '/api/token/') {
        return Promise.reject(error);
      }

      const newToken = await refreshToken();

      if (!newToken) {
        window.location.href = '/login';
      }

      localStorage.setItem('serviceToken', newToken);
      axiosServices.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;

      return axiosServices(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosServices;
