// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function TableBody(theme: Theme) {
  const hoverStyle = {
    '&:hover': {
      backgroundColor: theme.palette.secondary.lighter
    }
  };

  return {
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[0],
          '&.striped .MuiTableRow-root': {
            '&:nth-of-type(even)': {
              backgroundColor: theme.palette.grey[50]
            },
            ...hoverStyle
          },
          '& .MuiTableRow-root': {
            '&.pagination': {
              '&:hover': {
                backgroundColor: theme.palette.background.default
              }
            },
            ...hoverStyle,
            '&.assets-tbody:hover': {
              backgroundColor: 'inherit'
            },
            '&.bg-dim': {
              backgroundColor: theme.palette.grey[200],
              '&:hover': {
                backgroundColor: theme.palette.grey[200]
              }
            }
          }
        }
      }
    }
  };
}
