import { Grid, Stack, Switch, Link } from '@mui/material';
import LabeledFormikTextField from './LabeledFormikTextField';
import LabeledFormikInput from './LabeledFormikInput';
import CountryAutocomplete from './CountryAutocomplete';
import { Counterparty } from 'types/payments';

type Props = {
  formik: any;
  suggestedCounterparty: Counterparty | null;
  onSuggestedCounterpartyClick: () => void;
};

const AddCounterpartyStep = ({ formik, suggestedCounterparty, onSuggestedCounterpartyClick }: Props) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} lg={12}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.legal_name" fieldLabelId="legal-name" />
          {suggestedCounterparty && (
            <Link
              variant="caption"
              onClick={onSuggestedCounterpartyClick}
            >{`Did you mean counterparty ${suggestedCounterparty?.legal_name}?`}</Link>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.short_name" fieldLabelId="short-name" />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikInput formik={formik} fieldProperty="counterparty.is_business" fieldLabelId="is-business">
            <Switch name="counterparty.is_business" checked={formik.values.counterparty.is_business} onChange={formik.handleChange} />
          </LabeledFormikInput>
        </Stack>
      </Grid>
      {formik.values.counterparty.is_business && (
        <>
          <Grid item xs={12} lg={6}>
            <Stack spacing={0.5}>
              <LabeledFormikTextField formik={formik} fieldProperty="counterparty.company_id" fieldLabelId="company-id" />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={0.5}>
              <LabeledFormikTextField formik={formik} fieldProperty="counterparty.tax_id" fieldLabelId="tax-id" />
            </Stack>
          </Grid>
        </>
      )}
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.address_line_1" fieldLabelId="address-line-1" />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.address_line_2" fieldLabelId="address-line-2" />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.postal_code" fieldLabelId="postal-code" />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.city" fieldLabelId="city" />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikInput formik={formik} fieldProperty="counterparty.country" fieldLabelId="country">
            <CountryAutocomplete
              onChange={(value) => formik.setFieldValue('counterparty.country', value?.alpha2)}
              defaultValue={formik.initialValues.counterparty['country']}
            />
          </LabeledFormikInput>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={0.5}>
          <LabeledFormikTextField formik={formik} fieldProperty="counterparty.email" fieldLabelId="email" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddCounterpartyStep;
