import axios from 'utils/axios';
import {
  AssetInTimeParams,
  AssetsInTime,
  AssetsParams,
  AssetsSummary,
  BalanceReport,
  BalanceReportParams,
  BankAccount,
  BankAccountParams,
  Company,
  CompanyCounterparty,
  CompanyCounterpartyParams,
  CompanyParams,
  Counterparty,
  CounterpartyGroup,
  CounterpartyGroupParams,
  CounterpartyParams,
  CountryAssets,
  Currency,
  CurrencyAssets,
  PaginatedResults,
  Payment,
  PaymentParams,
  UserCompanyParams,
  Vendor,
  VendorAssets,
  VendorParams
} from '../types/payments';
import { UserCompany } from '../types/users';
import { AssetsAccountCategories } from '../types/api/assets';

export const fetchAccount = async (id: number | undefined): Promise<BankAccount> => {
  const response = await axios.get(`/api/bank_accounts/${id}/`);
  return response.data;
};

export const fetchAccountAudit = async (id: string | undefined) => {
  const response = await axios.get(`/api/bank_accounts/${id}/audit/`);
  return response.data;
};
export const fetchPaymentAudit = async (id: string | undefined) => {
  const response = await axios.get(`/api/payments/${id}/audit/`);
  return response.data;
};

export const fetchCompany = async (id: number | null | undefined | string): Promise<Company> => {
  const response = await axios.get(`/api/companies/${id}/`);
  return response.data;
};

export const fetchCounterparty = async (id: string | undefined): Promise<Counterparty> => {
  const response = await axios.get(`/api/counterparties/${id}/`);
  return response.data;
};

export const fetchVendor = async (id: number | null | undefined) => {
  const response = await axios.get(`/api/vendors/${id}/`);
  return response.data;
};

export const fetchCompanies = async (params: CompanyParams): Promise<PaginatedResults<Company>> => {
  const response = await axios.get('/api/companies/', {
    params: params
  });
  return response.data;
};

export const fetchCounterparties = async (params: CounterpartyParams): Promise<PaginatedResults<Counterparty>> => {
  const response = await axios.get('/api/counterparties/', {
    params: params
  });
  return response.data;
};

export const fetchCounterpartyGroups = async (params: CounterpartyGroupParams): Promise<PaginatedResults<CounterpartyGroup>> => {
  const response = await axios.get('/api/counterparty_groups/', {
    params: params
  });
  return response.data;
};
export const addCounterpartyGroup = (counterpartyGroup: CounterpartyGroup) => {
  return axios.post('/api/counterparty_groups/', counterpartyGroup);
};

export const fetchVendors = async (params: VendorParams): Promise<PaginatedResults<Vendor>> => {
  const response = await axios.get('/api/vendors/', {
    params: params
  });
  return response.data;
};

export const fetchCurrencies = async (): Promise<Currency[]> => {
  const response = await axios.get('/api/currencies/');
  return response.data;
};
export const fetchCapitalGroups = async (): Promise<any> => {
  const response = await axios.get('/api/capital_groups/');
  return response.data;
};
export const fetchBankAccountCategories = async (): Promise<any> => {
  const response = await axios.get('/api/bank_account_categories/');
  return response.data;
};

export const fetchConnectionStatuses = async (): Promise<any> => {
  const response = await axios.get('/api/connection_statuses/');
  return response.data;
};

export const fetchAccounts = async (params: BankAccountParams) => {
  const response = await axios.get('/api/bank_accounts/', {
    params: params
  });
  return response.data;
};

export const fetchAssets = async (params: AssetsParams) => {
  const response = await axios.get('/api/assets/', {
    params: params
  });
  return response.data;
};

export const createUpdateAccountMutation = (id: string | undefined) => {
  return async (account: BankAccount) => {
    const response = await axios.put(`/api/bank_accounts/${id}/`, account);
    return response.data;
  };
};

export const addAccountMutation = (account: BankAccount) => {
  return axios.post('/api/bank_accounts/', account);
};

export const addCompanyMutation = (company: Company) => {
  return axios.post('/api/companies/', company);
};

export const addCounterpartyMutation = (counterparty: Counterparty) => {
  return axios.post('/api/counterparties/', counterparty);
};

export const addBalanceReport = (balanceReport: BalanceReport) => {
  return axios.post('/api/balance_reports/', balanceReport);
};

export const fetchUserCompanies = async (params: UserCompanyParams): Promise<UserCompany> => {
  const response = await axios.get('/api/user_companies/', {
    params: params
  });
  return response.data;
};

export const createUpdateCompanyMutation = (id: string | undefined) => {
  return async (company: Company) => {
    const response = await axios.put(`/api/companies/${id}/`, company);
    return response.data;
  };
};

export const createUpdateCounterpartyMutation = (id: string | undefined) => {
  return async (counterparty: Counterparty) => {
    const response = await axios.put(`/api/counterparties/${id}/`, counterparty);
    return response.data;
  };
};

export const fetchAssetsPerCountry = async (): Promise<CountryAssets[]> => {
  const response = await axios.get('/api/stats/assets_per_country/');
  return response.data;
};

export const fetchAssetsPerCurrency = async (): Promise<CurrencyAssets[]> => {
  const response = await axios.get('/api/stats/assets_per_currency/');
  return response.data;
};

export const fetchAssetsPerVendor = async (): Promise<VendorAssets[]> => {
  const response = await axios.get('/api/stats/assets_per_vendor/');
  return response.data;
};

export const fetchAssetsInTime = async (): Promise<AssetsInTime[]> => {
  const response = await axios.get('/api/stats/assets_in_time/');
  return response.data;
};

export const fetchStatisticsAccountCategories = async (): Promise<AssetsAccountCategories> => {
  const response = await axios.get('/api/assets/statistics/account_categories/');
  return response.data;
};

export const fetchAssetInTime = async (params: AssetInTimeParams): Promise<AssetsInTime[]> => {
  const response = await axios.get('/api/stats/asset_in_time/', { params });
  return response.data;
};

export const fetchAssetsSummary = async (): Promise<AssetsSummary> => {
  const response = await axios.get('/api/stats/assets_summary/');
  return response.data;
};

export const fetchBankAccountsAssetsSummary = async (): Promise<AssetsSummary> => {
  const response = await axios.get('/api/stats/bank_accounts_assets_summary/');
  return response.data;
};

export const fetchAssetsTotalNumber = async (): Promise<{ total_assets: string }> => {
  const response = await axios.get('/api/stats/assets_total_number/');
  return response.data;
};

export const fetchSingleAssetSummary = async (params: AssetInTimeParams): Promise<AssetsSummary> => {
  const response = await axios.get('/api/stats/single_asset_summary/', { params });
  return response.data;
};

export const fetchCompanyCounterparties = async (params: CompanyCounterpartyParams): Promise<PaginatedResults<CompanyCounterparty>> => {
  const response = await axios.get('/api/company_counterparties/', {
    params: params
  });
  return response.data;
};

export const addCompanyCounterparty = (companyCounterparty: CompanyCounterparty) => {
  return axios.post('/api/company_counterparties/', companyCounterparty);
};

export const fetchBalanceReports = async (params: BalanceReportParams): Promise<BalanceReport[]> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.get('/api/balance_reports/', { params, headers: { timezone } });
  return response.data;
};
export const fetchPayments = async (params: PaymentParams): Promise<PaginatedResults<Payment[]>> => {
  const response = await axios.get('/api/payments/', { params });
  return response.data;
};

export const fetchPayment = async (id: string | undefined): Promise<Payment> => {
  const response = await axios.get(`/api/payments/${id}/`);
  return response.data;
};

export const fetchSimiliarAccount = async (account_number: string): Promise<BankAccount> => {
  const response = await axios.get(`/api/bank_accounts/find_similiar/`, { params: { account_number: account_number } });
  return response.data;
};

export const fetchSimiliarCounterparty = async (legal_name: string): Promise<Counterparty> => {
  const response = await axios.get(`/api/counterparties/find_similiar/`, { params: { legal_name: legal_name } });
  return response.data;
};

export const addPayment = (payment: FormData) => {
  return axios.post('/api/payments/', payment);
};

export const deletePayment = (id: string) => {
  return axios.delete(`/api/payments/${id}`);
};

export const createUpdatePaymentMutation = (id: string | undefined) => {
  return async (payment: FormData) => {
    const response = await axios.put(`/api/payments/${id}/`, payment);
    return response.data;
  };
};

export const fetchPaymentAttachment = async (paymentId: string, attachmentName: string) => {
  const response = await axios.get(`/api/payments/${paymentId}/download/${attachmentName}/`, {
    responseType: 'blob'
  });
  return response.data;
};

export const fetchBalanceReportsForDay = async (id: number) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.get(`/api/balance_reports/${id}/get_balance_reports_for_given_day/`, { headers: { timezone } });
  return response.data;
};

export const QUERY_KEY_BANK_ACCOUNTS = 'bank-accounts';
export const QUERY_KEY_ASSETS = 'assets';
export const QUERY_KEY_STATS_ASSETS_IN_TIME = 'stats-assets-in-time';
export const QUERY_KEY_STATS_ASSET_IN_TIME = 'stats-asset-in-time';
export const QUERY_KEY_STATS_ASSETS_PER_VENDOR = 'stats-assets-per-vendor';
export const QUERY_KEY_STATS_ASSETS_PER_CURRENCY = 'stats-assets-per-currency';
export const QUERY_KEY_STATS_ASSETS_PER_COUNTRY = 'stats-assets-per-country';
export const QUERY_KEY_STATS_ASSETS_TOTAL_NUMBER = 'stats-assets_total_number';
export const QUERY_KEY_COMPANIES = 'companies';
export const QUERY_KEY_COUNTERPARTIES = 'counterparties';
export const QUERY_KEY_USER_COMPANIES = 'user-companies';
export const QUERY_KEY_CURRENCIES = 'currencies';
export const QUERY_KEY_VENDORS = 'vendors';
export const QUERY_KEY_USER_BANK_ACCOUNT_AUDIT = 'bank-account-audit';
export const QUERY_KEY_COMPANY_COUNTERPARTIES = 'company-counterparties';
export const QUERY_KEY_COUNTERPARTY_GROUPS = 'counterparty-groups';
export const QUERY_KEY_STATS_ASSETS_SUMMARY = 'stats-assets-summary';
export const QUERY_KEY_STATS_BANK_ACCOUNTS_ASSETS_SUMMARY = 'stats-bank-accounts-assets-summary';
export const QUERY_KEY_STATS_SINGLE_ASSET_SUMMARY = 'stats-single-asset-summary';
export const QUERY_KEY_BALANCE_REPORTS = 'balance-reports';
export const QUERY_KEY_PAYMENTS = 'payments';
export const QUERY_KEY_PAYMENT_AUDIT = 'payment-audit';
export const QUERY_KEY_BALANCE_REPORT_FOR_DAY = 'balance-report-day';
export const QUERY_KEY_CAPITAL_GROUPS = 'capital-groups';
export const QUERY_KEY_CONNECTION_STATUSES = 'connection-statuses';
export const QUERY_KEY_BANK_ACCOUNT_CATEGORIES = 'bank-account-categories';
export const QUERY_KEY_ASSETS_STATISTICS_ACCOUNT_CATEGORIES = 'assets-statistics-account_categories';
