import { InputLabel, Stack } from '@mui/material';

import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentLineItems } from './PaymentLineItems';

export type Props = {
  formik: any;
  children: ReactElement | undefined;
  fieldProperty: string;
  fieldLabelId: string;
};

const LabeledFormikAmountInput = ({ formik, children, fieldProperty, fieldLabelId }: Props) => {
  return (
    <>
      <InputLabel>
        <FormattedMessage id={fieldLabelId} />
      </InputLabel>
      <Stack style={{ border: '1px solid #e6ebf1', borderRadius: 5 }}>
        {children}
        <PaymentLineItems formik={formik} />
      </Stack>
    </>
  );
};

export default LabeledFormikAmountInput;
