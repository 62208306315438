import { Stack, TextField } from '@mui/material';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import LabeledFormikAmountInput from './LabeledFormikAmountInput';
import CurrencyAutocomplete from './CurrencyAutocomplete';

interface NumericFieldProps {
  formik: any;
  fieldProperty: string;
  fieldLabelId: string;
  onChange: (value: NumberFormatValues | null) => any;
}

const LabeledFormikPaymentAmountField = ({ fieldProperty, fieldLabelId, formik, onChange }: NumericFieldProps) => {
  return (
    <LabeledFormikAmountInput formik={formik} fieldProperty={fieldProperty} fieldLabelId={fieldLabelId}>
      <Stack
        flex={1}
        direction={{
          sx: 'column',
          md: 'row'
        }}
      >
        <CurrencyAutocomplete
          placeholder="Currency"
          width={{ xs: '100%', sm: 200 }}
          onChange={(value) => formik.setFieldValue('amount_currency', value?.id)}
          defaultValue={formik.initialValues.amount_currency}
          style={{
            '& .MuiOutlinedInput-root': {
              xs: {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0
              },
              sm: {
                borderBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
              }
            }
          }}
        />
        <NumericFormat
          error={formik.touched[fieldProperty] && Boolean(formik.errors[fieldProperty])}
          label={Boolean(formik.touched[fieldProperty] && formik.errors[fieldProperty]) ? formik.errors[fieldProperty] : undefined}
          placeholder="Amount"
          customInput={TextField}
          variant="outlined"
          thousandSeparator={true}
          value={formik.values[fieldProperty] || ''}
          decimalScale={2}
          onValueChange={(value) => onChange(value)}
          style={{
            flex: 1
          }}
          sx={{
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              xs: {
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 0
              },
              sm: {
                borderTopRightRadius: 4
              }
            }
          }}
        />
      </Stack>
    </LabeledFormikAmountInput>
  );
};

export default LabeledFormikPaymentAmountField;
