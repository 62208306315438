import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import MainCard from '../MainCard';
import { FormattedMessage } from 'react-intl';
import { BankAccount, Counterparty } from '../../types/payments';
import { Box } from '@mui/system';
import AddBankAccountStep from './AddBankAccountStep';
import DetailsStep from './DetailsStep';
import { Stepper, Step, Stack, StepLabel, Button, Grid, CardActions, CardContent } from '@mui/material';
import AddCounterpartyStep from './AddCounterpartyStep';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { patternValidEmail } from 'utils/patterns';
import { fetchSimiliarAccount, fetchSimiliarCounterparty } from 'query/payments';
import _ from 'lodash';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24
};

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (value: BankAccount) => void;
};

const AddBankAccountModal = ({ open, onSubmit, handleClose }: Props) => {
  const steps = ['Bank account', 'Counterparty', 'Confirmation'];
  const [activeStep, setActiveStep] = useState(0);
  const [suggestedAccount, setSuggestedAccount] = useState<BankAccount | null>(null);
  const [suggestedCounterparty, setSuggestedCounterparty] = useState<Counterparty | null>(null);

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const initialValues: BankAccount = {
    name: '',
    description: '',
    company: null,
    counterparty: {
      id: null,
      short_name: '',
      legal_name: '',
      is_business: false,
      tax_id: '',
      company_id: '',
      address_line_1: '',
      address_line_2: '',
      postal_code: '',
      city: '',
      country: '',
      email: ''
    },
    currency: '',
    iban: '',
    account_number: '',
    vendor: null
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    currency: Yup.string().required('Currency is required'),
    counterparty: Yup.object().when([], {
      is: () => activeStep === 1,
      then: Yup.object().shape({
        short_name: Yup.string().required('Counterparty short name is required'),
        legal_name: Yup.string().required('Counterparty legal name is required'),
        company_id: Yup.string().when('is_business', {
          is: true,
          then: Yup.string().required('Company id is required'),
          otherwise: Yup.string().notRequired()
        }),
        tax_id: Yup.string().when('is_business', {
          is: true,
          then: Yup.string().required('Tax id is required'),
          otherwise: Yup.string().notRequired()
        }),
        is_business: Yup.boolean().required('Counterparty is_business is required'),
        address_line_1: Yup.string().required('Counterparty address line 1 is required'),
        postal_code: Yup.string().required('Counterparty postal code is required'),
        city: Yup.string().required('Counterparty city is required'),
        country: Yup.string().required('Counterparty country is required'),
        email: Yup.string().matches(patternValidEmail, 'Enter valid email')
      }),
      otherwise: Yup.object().nullable()
    }),
    iban: Yup.string().required('IBAN is required'),
    account_number: Yup.string().required('Account number is required'),
    vendor: Yup.mixed().required('Vendor is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (account: BankAccount) => {
      if (activeStep === steps.length - 1) {
        onSubmit(account);
        formik.resetForm({});
        setActiveStep(0);
        setSuggestedAccount(null);
        setSuggestedCounterparty(null);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  });
  useEffect(() => {
    const account = formik.values.account_number;
    if (account !== null && account.length > 10) {
      fetchSimiliarAccount(account).then((data) => {
        if (!_.isEmpty(data)) {
          setSuggestedAccount(data);
          return;
        } else {
          setSuggestedAccount(null);
          return;
        }
      });
    }
    setSuggestedAccount(null);
  }, [formik.values.account_number, setSuggestedAccount]);

  useEffect(() => {
    if (typeof formik.values.counterparty === 'object') {
      const legal_name = formik.values.counterparty?.legal_name;
      if (legal_name !== null && legal_name !== undefined && legal_name.length > 10) {
        fetchSimiliarCounterparty(legal_name).then((data) => {
          if (!_.isEmpty(data)) {
            setSuggestedCounterparty(data);
            return;
          } else {
            setSuggestedCounterparty(null);
            return;
          }
        });
      }
      setSuggestedAccount(null);
    }
  }, [formik.values.counterparty, setSuggestedCounterparty]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <AddBankAccountStep
            formik={formik}
            onSuggesstedAccountClick={() => {
              if (suggestedAccount) {
                formik.setValues(suggestedAccount);
                setActiveStep(2);
              }
            }}
            suggestedAccount={suggestedAccount}
          />
        );

      case 1:
        return (
          <AddCounterpartyStep
            formik={formik}
            onSuggestedCounterpartyClick={() => {
              if (suggestedCounterparty) {
                formik.setFieldValue('counterparty', suggestedCounterparty);
                setActiveStep(2);
              }
            }}
            suggestedCounterparty={suggestedCounterparty}
          />
        );
      case 2:
        return <DetailsStep formik={formik} />;
      default:
        return null;
    }
  };

  const handleCancel = () => {
    formik.resetForm({});
    setActiveStep(0);
    setSuggestedCounterparty(null);
    setSuggestedAccount(null);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <MainCard title={<FormattedMessage id="add-bank-account" />}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={12}>
                  <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: '2rem' }}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <>{getStepContent(activeStep)}</>
              </Grid>
            </CardContent>
            <CardActions>
              <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: 1, px: 1.5, py: 0.75 }}>
                {activeStep !== steps.length - 1 && (
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button color="error" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button type="submit" onClick={() => formik.handleSubmit()}>
                    Confirm
                  </Button>
                ) : (
                  <Button type="submit" onClick={() => formik.handleSubmit()}>
                    Next
                  </Button>
                )}
              </Stack>
            </CardActions>
          </MainCard>
        </Box>
      </Modal>
    </>
  );
};

export default AddBankAccountModal;
