// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

import { Theme } from '@mui/material/styles';

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12
        },
        caption: {
          color: theme.palette.grey['500']
        }
      }
    }
  };
}
