// @ts-nocheck
import { Button, CardActions, CardContent, Divider, Grid, Stack } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { AddPaymentRequest, LineItem, PaymentAction } from 'types/payments';
import { ReactNode, useState } from 'react';
import LabeledFormikTextField from './LabeledFormikTextField';
import LabeledFormikInput from './LabeledFormikInput';
import BankAccountAutocomplete from './BankAccountAutocomplete';
import LabeledFormikDatePicker from './LabeledFormikDatePicker';
import LabeledFormikRadioGroup from './LabeledFormikRadioGroup';
import dayjs from 'dayjs';
import AddBankAccountModal from './AddBankAccountModal';
import { fetchAccounts, QUERY_KEY_BANK_ACCOUNTS } from 'query/payments';
import { BankAccount } from 'types/payments';
import * as Yup from 'yup';
import _ from 'lodash';
import LabeledFormikPaymentAmountField from './LabeledFormikPaymentAmountField';
import PaymentAttachments from './PaymentAttachments';
import { LoadingButton } from '@mui/lab';

type Props = {
  initialValues: AddPaymentRequest;
  // mutationFn: (payment: AddPaymentRequest) => Promise<AxiosResponse>;
  mutationFn: (payment: FormData) => Promise<AxiosResponse>;
  successUrl: string;
  cancelUrl: string;
  submitButtonText: ReactNode | string;
};

export const PaymentForm = ({ initialValues, mutationFn, successUrl, cancelUrl, submitButtonText }: Props) => {
  const [fromAccountOptions, setFromAccountOptions] = useState<BankAccount[]>();
  const [toAccountOptions, setToAccountOptions] = useState<BankAccount[]>();

  const { isLoading } = useQuery([QUERY_KEY_BANK_ACCOUNTS], () => fetchAccounts({}), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess: (data) => {
      setFromAccountOptions(data.results.filter((obj: BankAccount) => obj.company_name !== null));
      setToAccountOptions(data.results);
    }
  });

  const navigate = useNavigate();
  const mutation = useMutation(mutationFn, {
    onSuccess: () => navigate(successUrl),
    onError: (error: AxiosError<any>) => {
      if (error.response?.status === 400) {
        for (const field in error.response.data) {
          formik.setFieldError(field, error.response.data[field].join(' '));
        }
      }
    }
  });
  const [open, setOpen] = useState(false);
  const [openAccountField, setOpenAccountField] = useState<string | null>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    amount_currency: Yup.string().required('Currency is required'),
    amount: Yup.string().required('Amount is required'),
    from_account: Yup.object().required('From account is required'),
    to_account: Yup.object().required('To account is required'),
    payment_date: Yup.string().required('Payment date is required'),
    attachments: Yup.array(),
    line_items: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Line item name is required.'),
          price: Yup.number().required('Price is required.')
        })
      )
      .test('line-items-sum-amount', 'Line items do not sum up to the payment amount.', (lineItems, ctx) => {
        if (!ctx.parent.amount || !lineItems?.length) return true;

        const amount = Number(ctx.parent.amount);
        const lineItemAmount = _.sumBy(lineItems, (lineItem) => lineItem.price ?? 0);

        return amount === lineItemAmount;
      })
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (payment: AddPaymentRequest) => {
      if (typeof payment.from_account === 'object') {
        if (payment.from_account && payment.from_account.id) {
          payment.from_account = payment.from_account.id;
        }
      }
      if (typeof payment.to_account === 'object') {
        // If account have id its already have counterparty or company assigned so
        // we send only id
        if (payment.to_account.id) {
          payment.to_account = payment.to_account.id;
        } else if (typeof payment.to_account.counterparty === 'object') {
          if (payment.to_account.counterparty?.id) {
            payment.to_account.counterparty = payment.to_account.counterparty.id;
          }
        }
      }

      const form = new FormData();
      const { attachments = [], line_items = [], to_account, ...partialPayment } = payment;

      if (typeof to_account === 'object') {
        form.append('to_account', JSON.stringify(to_account));
      } else {
        form.append('to_account', to_account?.toString());
      }

      for (let key in partialPayment) {
        form.append(key, partialPayment[key]);
      }

      for (const [i, line_item] of line_items.entries()) {
        for (let key in line_item) {
          form.append(`line_items[${i}]${key}`, line_item[key as keyof LineItem]?.toString());
        }
      }

      for (let attachment of attachments) {
        if ('id' in attachment) {
          form.append('attachments[]', attachment.id.toString());
        } else {
          form.append('attachments', attachment);
        }
      }

      mutation.mutate(form);
    }
  });

  const handleOpen = (field: string) => {
    setOpenAccountField(field);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!isLoading) {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={6}>
                <Stack spacing={0.5}>
                  <LabeledFormikTextField formik={formik} fieldProperty="name" fieldLabelId="name" />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={0.5}>
                  <LabeledFormikDatePicker
                    formik={formik}
                    fieldProperty="payment_date"
                    fieldLabelId="payment-date"
                    onChange={(value) => formik.setFieldValue('payment_date', dayjs(value).format('YYYY-MM-DD'))}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Stack spacing={0.5}>
                  <LabeledFormikRadioGroup
                    formik={formik}
                    fieldProperty="action"
                    fieldLabelId="action"
                    options={Object.values(PaymentAction)}
                  />
                </Stack>
              </Grid>
              {formik.values.action === PaymentAction.PAY && (
                <>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={0.5}>
                      <LabeledFormikInput formik={formik} fieldProperty="from_account" fieldLabelId="from-account">
                        <BankAccountAutocomplete
                          formik={formik}
                          exclusiveFieldName="to_account"
                          fieldName="from_account"
                          onChange={(value) => formik.setFieldValue('from_account', value ?? undefined)}
                          value={formik.values.from_account}
                          options={fromAccountOptions}
                        />
                      </LabeledFormikInput>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={0.5}>
                      <LabeledFormikInput formik={formik} fieldProperty="to_account" fieldLabelId="to-account">
                        <BankAccountAutocomplete
                          formik={formik}
                          exclusiveFieldName="from_account"
                          fieldName="to_account"
                          onChange={(value) => formik.setFieldValue('to_account', value ?? undefined)}
                          value={formik.values.to_account}
                          options={toAccountOptions}
                          onButtonClick={() => handleOpen('to_account')}
                        />
                      </LabeledFormikInput>
                    </Stack>
                  </Grid>
                </>
              )}
              {formik.values.action === PaymentAction.CHARGE && (
                <>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={0.5}>
                      <LabeledFormikInput formik={formik} fieldProperty="to_account" fieldLabelId="to-account">
                        <BankAccountAutocomplete
                          exclusiveFieldName="from_account"
                          value={formik.values.to_account}
                          fieldName="to_account"
                          formik={formik}
                          onChange={(value) => formik.setFieldValue('to_account', value ?? undefined)}
                          onButtonClick={() => handleOpen('to_account')}
                          options={toAccountOptions}
                        />
                      </LabeledFormikInput>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={0.5}>
                      <LabeledFormikInput formik={formik} fieldProperty="from_account" fieldLabelId="from-account">
                        <BankAccountAutocomplete
                          formik={formik}
                          value={formik.values.from_account}
                          exclusiveFieldName="to_account"
                          fieldName="from_account"
                          onChange={(value) => formik.setFieldValue('from_account', value ?? undefined)}
                          options={fromAccountOptions}
                        />
                      </LabeledFormikInput>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} lg={6} marginTop={3}>
              <Stack spacing={0.5} direction="column">
                <LabeledFormikPaymentAmountField
                  formik={formik}
                  fieldProperty="amount"
                  fieldLabelId="amount"
                  onChange={(value) => formik.setFieldValue('amount', value?.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6} marginTop={3}>
              <Stack spacing={0.5} direction="column">
                <PaymentAttachments formik={formik} />
              </Stack>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: 1, px: 1.5, py: 0.75 }}>
              <Button color="error" href={cancelUrl}>
                Cancel
              </Button>
              <LoadingButton variant="contained" type="submit" loading={formik.isSubmitting}>
                {submitButtonText}
              </LoadingButton>
            </Stack>
          </CardActions>
          {openAccountField && toAccountOptions && (
            <>
              <AddBankAccountModal
                open={open}
                onSubmit={(value) => {
                  if (toAccountOptions.findIndex((e) => e.account_number === value.account_number) === -1) {
                    setToAccountOptions([...toAccountOptions, value]);
                  }
                  formik.setFieldValue('to_account', value);
                  handleClose();
                }}
                handleClose={handleClose}
              />
            </>
          )}
        </form>
      </>
    );
  }
  return null;
};
