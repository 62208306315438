// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme: Theme) {
  const commonCell = {
    '&:not(:last-of-type)': {
      position: 'relative'
    }
  };

  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          fontSize: '0.875rem',
          padding: 12,
          borderColor: theme.palette.divider,
          '& .MuiLink-root': {
            color: theme.palette.grey[500],
            fontWeight: 'bold'
          },
          '&.assets-header--outer': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '19px',
            cursor: 'pointer',
            padding: '0px 5px',
            '&:first-of-type': {
              width: '10px'
            },
            '& svg': {
              color: theme.palette.text.primary,
              fontSize: '2rem'
            }
          },
          '& .text-large--secondary': {
            color: theme.palette.grey[500],
            fontSize: '18px',
            fontWeight: 'bold'
          },
          '& .text--dark': {
            color: theme.palette.grey[900],
            fontSize: '13px'
          },
          '& .text-large--primary': {
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 'bold'
          },
          '& .text-large': {
            fontSize: '18px'
          },
          '& .text--primary': {
            color: theme.palette.text.primary
          }
        },
        sizeSmall: {
          padding: 10
        },
        head: {
          fontSize: '0.8rem',
          fontWeight: 700,
          color: theme.palette.grey[900],
          textTransform: 'uppercase',
          ...commonCell
        },
        footer: {
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          ...commonCell
        }
      }
    }
  };
}
