import axios from 'utils/axios';
import { PaginatedResults } from 'types/payments';
import { UserProfile } from 'types/auth';
import { UserCompany, UserParams, UserSettings } from '../types/users';

export const fetchUsers = async (params: UserParams): Promise<PaginatedResults<UserProfile>> => {
  const response = await axios.get('/api/users/', {
    params: params
  });
  return response.data;
};

export const fetchUser = async (id: string | undefined) => {
  const response = await axios.get(`/api/users/${id}/`);
  return response.data;
};

export const addUserCompany = (userCompany: UserCompany) => {
  return axios.post('/api/user_companies/', userCompany);
};

export const deleteUserCompany = async (id: number): Promise<any> => {
  const response = await axios.delete(`/api/user_companies/${id}/`);
  return response.data;
};

export const addUser = async (user: UserProfile) => {
  return await axios.post('/api/users/', user);
};

export const createUpdateUserMutation = (id: string | undefined) => {
  return async (user: UserProfile) => {
    const response = await axios.put(`/api/users/${id}/`, user);
    return response.data;
  };
};

export const updateUserSettings = async (userSettings: UserSettings): Promise<UserSettings> => {
  const response = await axios.patch('/api/users/user_settings/', userSettings);
  return response.data;
};

export const fetchUserSettings = async (): Promise<UserSettings> => {
  const response = await axios.get('/api/users/user_settings/');
  return response.data;
};

export const sendPasswordResetToken = (email: string) => {
  return axios.post('/api/password_reset/', { email });
};

export const validatePasswordResetToken = (token: string) => {
  return axios.post('/api/password_reset/validate_token/', { token });
};

interface ResetPasswordVariables {
  token: string;
  password: string;
}

export const resetPassword = ({ token, password }: ResetPasswordVariables) => {
  return axios.post('/api/password_reset/confirm/', { token, password });
};

interface ChangePasswordVariables {
  old_password: string;
  new_password: string;
}

export const changePassword = ({ old_password, new_password }: ChangePasswordVariables) => {
  return axios.put('/api/users/change_password/', { old_password, new_password });
};

export const QUERY_KEY_USER_SETTINGS = 'user-settings';
export const QUERY_KEY_USERS = 'users';
