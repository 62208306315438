import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { ReactElement } from 'react';
import { UserRole } from '../types/auth';

export type Props = {
  allowedRoles: UserRole[];
  children: ReactElement | null;
};

export const RoleAwareRoute = ({ allowedRoles, children }: Props) => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  if (!allowedRoles.includes(user.role)) {
    switch (user?.role) {
      case UserRole.FinancialDepartmentManager:
        return <Navigate to="/" replace />;
      case UserRole.FinancialDepartmentEmployee:
        return <Navigate to="/" replace />;
      case UserRole.BusinessRepresentative:
        return <Navigate to="/payments" replace />;
      default:
        return <Navigate to="/payments" replace />;
    }
  }

  return children;
};
