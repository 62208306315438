import { TextField } from '@mui/material';
import LabeledFormikInput from './LabeledFormikInput';
import { get } from 'lodash';

export type Props = {
  formik: any;
  fieldProperty: string;
  fieldLabelId: string;
};

const LabeledFormikTextField = ({ formik, fieldProperty, fieldLabelId }: Props) => {
  return (
    <LabeledFormikInput formik={formik} fieldProperty={fieldProperty} fieldLabelId={fieldLabelId}>
      <TextField fullWidth name={fieldProperty} value={get(formik.values, fieldProperty) || ''} onChange={formik.handleChange} />
    </LabeledFormikInput>
  );
};

export default LabeledFormikTextField;
