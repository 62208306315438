import { Autocomplete, ListSubheader, TextField, Paper, Button, ListItem, Stack, Typography } from '@mui/material';
import { BankAccount } from '../../types/payments';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

type Props = {
  onChange: (value: BankAccount | null) => any;
  formik: any;
  exclusiveFieldName?: string;
  fieldName: string;
  value?: BankAccount;
  onButtonClick?: () => void;
  options?: BankAccount[];
};

const BankAccountAutocomplete = ({ onChange, formik, exclusiveFieldName, onButtonClick, options, value }: Props) => {
  if (options) {
    return (
      <Autocomplete
        options={options.sort((a, b) => {
          // Sort values to remove duplicated group by headers
          if (a.counterparty_name && !b.counterparty_name) {
            return -1; // a comes before b
          } else if (!a.counterparty_name && b.counterparty_name) {
            return 1; // a comes after b
          } else if (a.company_name && !b.company_name) {
            return -1; // a comes before b
          } else if (!a.company_name && b.company_name) {
            return 1; // a comes after b
          } else {
            return 0; // maintain the order
          }
        })}
        groupBy={(option: BankAccount) => (option.counterparty_name ? 'Counterparties' : 'Companies')}
        onChange={(_, value) => onChange(value)}
        getOptionLabel={(option: BankAccount) => option.name || ''}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            (item) =>
              item.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
              item.account_number?.includes(inputValue) ||
              item.company_name?.toLowerCase().includes(inputValue.toLowerCase()) ||
              item.counterparty_name?.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        getOptionDisabled={(option: BankAccount) => option.account_number === formik.values[exclusiveFieldName || '']?.account_number}
        value={_.find(options, (option) => option.account_number === value?.account_number) || null}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <Stack spacing={2} direction="row" alignItems="center">
              {option.counterparty_name ? (
                <ListSubheader sx={{ px: 1, py: 0.1 }}>{option.counterparty_name}</ListSubheader>
              ) : (
                <ListSubheader sx={{ px: 1, py: 0.1 }}>{option.company_name}</ListSubheader>
              )}
              <Typography>{` ${option.name} (**** ${option.account_number?.slice(option.account_number?.length - 4)} )`}</Typography>
            </Stack>
          </ListItem>
        )}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {children}
              {onButtonClick && (
                <Button variant="contained" fullWidth onClick={onButtonClick} onMouseDown={onButtonClick}>
                  <FormattedMessage id="add-bank-account" />
                </Button>
              )}
            </Paper>
          );
        }}
      />
    );
  }

  return null;
};

export default BankAccountAutocomplete;
