// project import
import organization from './organization';
import { NavItemType } from 'types/menu';
import assets from './assets';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [assets, organization]
};

export default menuItems;
