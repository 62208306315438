import countries from './countries.json';
import _ from 'lodash';
import { Country } from 'types/payments';

export const getCountryName = (code: string): string | undefined => _.find(countries, (country) => country['alpha-2'] === code)?.name;

export const getAllCountries = (): Country[] =>
  countries.map((country) => {
    return {
      alpha2: country['alpha-2'],
      alpha3: country['alpha-3'],
      name: country.name
    };
  });
