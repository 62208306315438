import { ReactElement } from 'react';

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

export enum UserRole {
  FinancialDepartmentManager = 'FINANCIAL_DEPARTMENT_MANAGER',
  FinancialDepartmentEmployee = 'FINANCIAL_DEPARTMENT_EMPLOYEE',
  BusinessRepresentative = 'BUSINESS_REPRESENTATIVE'
}

export type UserProfile = {
  id?: number | null;
  first_name: string;
  last_name: string;
  username: string;
  role: UserRole;
  password?: string;
  email: string;
};

export interface AuthProps {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null;
  token?: string | null;
  redirect?: string;
}

export interface AuthActionProps {
  type: string;
  payload?: AuthProps;
}

export type JWTContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
  setLoginRedirect: (redirectPath: string) => void;
  clearLoginRedirect: () => void;
  redirect?: string;
};
