import { Grid, Link, Stack } from '@mui/material';
import CurrencyAutocomplete from './CurrencyAutocomplete';
import LabeledFormikTextField from './LabeledFormikTextField';
import LabeledFormikInput from './LabeledFormikInput';
import VendorAutocomplete from './VendorAutocomplete';
import { BankAccount } from 'types/payments';
import { fetchCompany, QUERY_KEY_COMPANIES } from 'query/payments';
import { useQuery } from 'react-query';

type Props = {
  formik: any;
  suggestedAccount: BankAccount | null;
  onSuggesstedAccountClick: () => void;
};

const AddBankAccountStep = ({ formik, suggestedAccount, onSuggesstedAccountClick }: Props) => {
  const companyId = suggestedAccount?.company ?? undefined;

  const { data: company } = useQuery([QUERY_KEY_COMPANIES, { id: companyId }], () => fetchCompany(companyId), {
    enabled: companyId !== undefined
  });

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} lg={12} sx={{ height: '8rem' }}>
          <Stack spacing={0.5}>
            <LabeledFormikTextField formik={formik} fieldProperty="account_number" fieldLabelId="account-number" />
            {suggestedAccount && typeof suggestedAccount.counterparty === 'object' && suggestedAccount.counterparty && (
              <Link variant="caption" onClick={onSuggesstedAccountClick}>{`Did you mean account ${
                suggestedAccount.name
              } assigned to ${suggestedAccount.counterparty.short_name.substring(0, 25)} ? `}</Link>
            )}
            {company && suggestedAccount && (
              <Link variant="caption" onClick={onSuggesstedAccountClick}>{`Did you mean account ${
                suggestedAccount.name
              } assigned to ${company.short_name.substring(0, 25)} ? `}</Link>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={0.5}>
            <LabeledFormikTextField formik={formik} fieldProperty="name" fieldLabelId="name" />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={0.5}>
            <LabeledFormikTextField formik={formik} fieldProperty="description" fieldLabelId="description" />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={0.5}>
            <LabeledFormikInput formik={formik} fieldProperty="vendor" fieldLabelId="vendor">
              <VendorAutocomplete defaultVendorId={formik.values.vendor} onChange={(value) => formik.setFieldValue('vendor', value?.id)} />
            </LabeledFormikInput>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={0.5}>
            <LabeledFormikInput formik={formik} fieldProperty="currency" fieldLabelId="currency">
              <CurrencyAutocomplete
                onChange={(value) => formik.setFieldValue('currency', value?.id)}
                defaultValue={formik.initialValues.currency}
              />
            </LabeledFormikInput>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={0.5}>
            <LabeledFormikTextField formik={formik} fieldProperty="iban" fieldLabelId="iban" />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AddBankAccountStep;
