import { Autocomplete, TextField } from '@mui/material';
import { Currency } from '../../types/payments';
import { useQuery } from 'react-query';
import { fetchCurrencies, QUERY_KEY_CURRENCIES } from 'query/payments';
import _ from 'lodash';

type Props = {
  defaultValue?: string | null;
  width?: any;
  style?: any;
  placeholder?: string;
  onChange: (value: Currency | null) => any;
};

const CurrencyAutocomplete = ({ defaultValue, onChange, placeholder, style = {}, width = 300 }: Props) => {
  const { data: currencies } = useQuery(QUERY_KEY_CURRENCIES, fetchCurrencies);

  if (currencies) {
    return (
      <Autocomplete
        disablePortal
        options={currencies}
        renderInput={(params) => <TextField placeholder={placeholder} {...params} />}
        getOptionLabel={(option: Currency) => `${option.id} (${option.name})`}
        isOptionEqualToValue={(option: Currency, value: Currency) => option.id === value.id}
        onChange={(e, value) => onChange(value)}
        sx={{
          ...style,
          width
        }}
        defaultValue={_.find(currencies, (currency) => currency.id === defaultValue) || null}
      />
    );
  }

  return null;
};

export default CurrencyAutocomplete;
