import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useDispatch } from 'store';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, setLoginRedirect, clearLoginRedirect, redirect } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      setLoginRedirect(location.pathname + location.search);
      navigate('login', { replace: true });
    } else {
      if (redirect) {
        clearLoginRedirect();
        navigate(redirect, { replace: true });
      }
    }
  }, [isLoggedIn, navigate, location, dispatch, setLoginRedirect, redirect, clearLoginRedirect]);

  return children;
};

export default AuthGuard;
