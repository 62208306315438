import { FormattedMessage } from 'react-intl';
import { DollarOutlined, HomeOutlined, SwapOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';
import { UserRole } from '../types/auth';

const assets: NavItemType = {
  id: 'assets',
  title: <FormattedMessage id="assets" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: HomeOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]
    },
    {
      id: 'assets',
      title: <FormattedMessage id="assets" />,
      type: 'item',
      url: '/assets',
      icon: DollarOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]
    },
    {
      id: 'payments',
      title: <FormattedMessage id="payments" />,
      type: 'item',
      url: '/payments',
      icon: SwapOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee, UserRole.BusinessRepresentative]
    }
  ]
};

export default assets;
