// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableHead(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[0],
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `2px solid ${theme.palette.divider}`,
          boxShadow: `0 8px 6px -6px black`,
          minHeight: '50px',
          '&.assets': {
            backgroundColor: theme.palette.background.default
          },
          '&.assets-thead': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'none',
            border: 'none',
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'inherit'
            },
            '& .MuiTableCell-head': {
              fontSize: '0.7rem',
              margin: 0,
              padding: 0,
              paddingTop: '10px',
              paddingBottom: '5px',
              paddingLeft: '10px',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            }
          }
        }
      }
    }
  };
}
