import { useState } from 'react';

// material-ui
import { Box, Drawer, InputLabel, Stack } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';

// assets
import { CloseCircleOutlined, SettingOutlined } from '@ant-design/icons';
import CurrencyAutocomplete from 'components/organization/CurrencyAutocomplete';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEY_USER_SETTINGS, updateUserSettings } from 'query/users';
import { AxiosError } from 'axios';
import { UserSettings } from 'types/users';
import useUserSettings from 'hooks/useUserSettings';
import {
  QUERY_KEY_BALANCE_REPORTS,
  QUERY_KEY_BANK_ACCOUNTS,
  QUERY_KEY_STATS_ASSETS_IN_TIME,
  QUERY_KEY_STATS_ASSETS_PER_COUNTRY,
  QUERY_KEY_STATS_ASSETS_PER_CURRENCY,
  QUERY_KEY_STATS_ASSETS_PER_VENDOR,
  QUERY_KEY_STATS_ASSETS_SUMMARY
} from 'query/payments';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const Customization = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const queryClient = useQueryClient();

  const userSettings = useUserSettings();

  const mutation = useMutation(updateUserSettings, {
    onSuccess: (userSettings: UserSettings) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER_SETTINGS] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_BANK_ACCOUNTS] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATS_ASSETS_PER_COUNTRY] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATS_ASSETS_PER_VENDOR] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATS_ASSETS_PER_CURRENCY] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATS_ASSETS_IN_TIME] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATS_ASSETS_SUMMARY] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_BALANCE_REPORTS] });
    },
    onError: (error: AxiosError<any>) => {
      console.log(error);
    }
  });

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton color="secondary" variant="light" onClick={handleToggle} aria-label="settings toggler">
          <SettingOutlined />
        </IconButton>
      </Box>
      <Drawer
        sx={{
          zIndex: 2001
        }}
        anchor="right"
        onClose={handleToggle}
        open={open}
      >
        {open && (
          <MainCard
            title="Settings"
            sx={{
              border: 'none',
              borderRadius: 0,
              height: '100vh'
            }}
            content={false}
            secondary={
              <IconButton shape="rounded" size="small" onClick={handleToggle} sx={{ color: 'background.paper' }}>
                <CloseCircleOutlined style={{ fontSize: '1.15rem' }} />
              </IconButton>
            }
          >
            <Stack direction="row" spacing={1} sx={{ width: 1, px: 1.5, py: 0.75 }}>
              <Stack spacing={0.5}>
                <InputLabel>Base currency</InputLabel>
                <CurrencyAutocomplete
                  onChange={(value) => mutation.mutate({ base_currency: value?.id })}
                  defaultValue={userSettings?.base_currency}
                />
              </Stack>
            </Stack>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
