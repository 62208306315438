import { ReactNode } from 'react';

export type BankAccount = {
  id?: number;
  name: string | null;
  description: string | null;
  company: number | null;
  counterparty: number | null | Counterparty;
  company_name?: string | null;
  category?: string | null;
  category_name?: string | null;
  currency: string;
  iban: string | null;
  account_number: string | null;
  vendor: number | null;
  external_id?: string;
  vendor_name?: string | null;
  counterparty_name?: string | null;
  latest_balance_report?: BalanceReport;
  connection_id?: string;
  connection_status?: 'ACTIVE' | 'INACTIVE' | 'DISABLED';
  status?: 'ACTIVE' | 'ARCHIVED';
};

export type Vendor = {
  id: number;
  name: string;
};

export type Currency = {
  id: string;
  name: string;
};

export type Country = {
  alpha2: string;
  alpha3: string;
  name: string;
};

export type Company = {
  id: number | null;
  short_name: string;
  legal_name: string;
  company_id: string;
  tax_id: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  city: string;
  country: string;
  email: string;
  capital_group: string | null;
  director: string;
  established_date: string | null;
};

export type Counterparty = {
  id: number | null;
  short_name: string;
  legal_name: string;
  is_business: boolean;
  tax_id: string;
  company_id: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  city: string;
  country: string;
  email: string;
};

export type CounterpartyGroup = {
  id?: number | null;
  name: string;
  // Needed for Autocomplete with add option
  title?: string;
};

export type BalanceReport = {
  id?: number;
  bank_account: number;
  amount: string;
  amount_currency: string;
  amount_converted?: number;
  amount_rate?: string;
  amount_base_currency_formatted?: string;
  date_added?: string;
  amount_formatted?: string;
};

export type Column<T> = {
  name: ReactNode | string;
  property: string;
  link?: boolean;
  renderer?: (row: T) => ReactNode | string;
  filter?: ReactNode;
  sx?: any;
};

export type PaginatedResults<T> = {
  count: number;
  results: T[];
};

export type PaginationParams = {
  page_size?: number;
  page?: number;
};

export type CompanyParams = PaginationParams & {
  id?: number;
  short_name__icontains?: string;
};

export type CounterpartyParams = PaginationParams & {
  id?: number;
  short_name__icontains?: string;
  vat_id__icontains?: string;
  country?: string;
};

export type CounterpartyGroupParams = PaginationParams & {
  name__icontains?: string;
};

export type VendorParams = PaginationParams & {
  id?: number;
  name__icontains?: string;
};

export type Assets = {
  amount: string | null;
  amount_formatted: string;
};

export type CountryAssets = Assets & {
  country: string;
};

export type CurrencyAssets = Assets & {
  currency: string;
};

export type VendorAssets = Assets & {
  vendor: string;
};

export type AssetsInTime = Assets & {
  date: string;
};

export type AssetsChange = Assets & {
  percentage_change: string;
};

export type AssetsSummary = {
  assets_now: Assets;
  assets_yesterday: AssetsChange;
  assets_in_time: AssetsChange;
};

export type CompanyCounterparty = {
  company: number;
  counterparty: number | null;
  groups: number[];
  counterparty_name?: string;
  group_names?: string[];
};

export type CompanyCounterpartyParams = PaginationParams & {
  company?: string;
};

export type UserCompanyParams = PaginationParams & {
  company?: string;
  user?: string;
};

export type BankAccountParams = PaginationParams & {
  name__icontains?: string;
  company?: string | undefined;
  counterparty?: string | undefined;
  vendor?: string;
  owned?: string;
};

export type AssetsParams = PaginationParams & {
  group_by: 'provider' | 'currency' | 'account_holder';
  owned?: string;
};

export type ConnectionParams = PaginationParams & {
  user_email?: string;
  provier_name__icontains?: string;
  status?: string;
};

export type BalanceReportParams = PaginationParams & {
  bank_account: number;
};

export type AssetInTimeParams = {
  asset_id?: number;
  start: string;
};

export type LineItem = {
  id: number;
  name: string;
  price: number;
};

export type Payment = {
  id?: number;
  name: string;
  action: PaymentAction;
  amount?: string;
  amount_currency?: string;
  from_account?: BankAccount;
  from_account_name?: string;
  to_account?: BankAccount;
  payment_date?: string;
  amount_formatted?: string;
  last_modified_by?: string;
  currency?: string;
  line_items?: LineItem[];
  attachments?: [];
};

export type PaymentParams = PaginationParams & {
  name__icontains?: string;
  to_account?: string | undefined;
  from_account?: string | undefined;
};

export enum PaymentAction {
  PAY = 'PAY',
  CHARGE = 'CHARGE'
}
// Asset filtering date options
export enum DateOptions {
  YearBeginning = 'this-year',
  FifteenDays = 'fifteen-days',
  OneYear = 'one-year',
  ThreeMonths = 'three-months',
  OneMonth = 'one-month'
}

// Small type wrapper around Payment due to complicated payment form structure
// we send from_account and to_account as whole objects but if they have id
// we just assign id to field
export type AddPaymentRequest = Omit<Payment, 'from_account' | 'to_account'> & {
  from_account?: BankAccount | number;
  to_account?: BankAccount | number;
};
