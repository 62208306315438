import { FormattedMessage } from 'react-intl';
import { BankOutlined, DeploymentUnitOutlined, LinkOutlined, TeamOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';
import { UserRole } from '../types/auth';

const organization: NavItemType = {
  id: 'organization',
  title: <FormattedMessage id="organization" />,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/users',
      icon: TeamOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager]
    },
    {
      id: 'companies',
      title: <FormattedMessage id="companies" />,
      type: 'item',
      url: '/companies',
      icon: BankOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]
    },
    {
      id: 'counterparties',
      title: <FormattedMessage id="counterparties" />,
      type: 'item',
      url: '/counterparties',
      icon: DeploymentUnitOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]
    },
    {
      id: 'conections',
      title: <FormattedMessage id="connections" />,
      type: 'item',
      url: '/connections',
      icon: LinkOutlined,
      allowedRoles: [UserRole.FinancialDepartmentManager]
    }
  ]
};

export default organization;
