import { Autocomplete, TextField } from '@mui/material';
import { Vendor, VendorParams } from '../../types/payments';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchVendor, fetchVendors, QUERY_KEY_VENDORS } from 'query/payments';
import { FormattedMessage } from 'react-intl';

type Props = {
  defaultVendorId?: number | null;
  onChange: (value: Vendor | null) => any;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
  labeled?: boolean;
};

const VendorAutocomplete = ({ defaultVendorId, onChange, size = 'medium', fullWidth = false, labeled = false }: Props) => {
  const [params, setParams] = useState<VendorParams>({
    name__icontains: '',
    page_size: 5
  });

  const { data: vendor } = useQuery([QUERY_KEY_VENDORS, { id: defaultVendorId }], () => fetchVendor(defaultVendorId), {
    enabled: defaultVendorId != null
  });

  const { data: vendors } = useQuery([QUERY_KEY_VENDORS, params], () => fetchVendors(params));

  const onInputChange = (value: string) =>
    setParams({
      ...params,
      name__icontains: value
    });

  if (defaultVendorId == null || vendor) {
    return (
      <Autocomplete
        disablePortal
        options={vendors?.results || []}
        getOptionLabel={(v: Vendor) => v.name}
        renderInput={(params) => <TextField {...params} label={labeled ? <FormattedMessage id="vendor" /> : ''} />}
        filterOptions={(x) => x}
        onInputChange={(e, value) => onInputChange(value)}
        defaultValue={vendor}
        isOptionEqualToValue={(option: Vendor, value: Vendor) => option.id === value.id}
        onChange={(e, value) => onChange(value)}
        size={size}
        fullWidth={fullWidth}
      />
    );
  }

  return null;
};

export default VendorAutocomplete;
