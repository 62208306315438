import logo from '../../assets/images/luthus-logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return <img src={logo} alt="Luthus" width="35" height="35px" />;
};

export default LogoIcon;
