import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Box, IconButton, InputLabel, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

type Props = {
  formik: any;
};

type PaymentAttachmentProps = {
  attachment: any;
  handleClick: () => void;
};

const PaymentAttachment = ({ attachment, handleClick }: PaymentAttachmentProps) => {
  return (
    <ListItem
      divider
      secondaryAction={
        <IconButton edge="end" aria-label="more" onClick={handleClick}>
          <DeleteOutlined />
        </IconButton>
      }
    >
      {attachment.name}
    </ListItem>
  );
};

const RejectedPaymentAttachment = ({ attachment }: { attachment: any }) => {
  const errors = attachment[0]?.errors;

  const errorMessage = useMemo(() => {
    const errorCodes = errors.map((error: any) => error.code);

    let errorMessage = [];

    if (errorCodes.includes('file-too-large')) {
      errorMessage.push('File is too large');
    }

    if (errorCodes.includes('file-invalid-type')) {
      errorMessage.push('File type is invalid');
    }

    return errorMessage.join(', ').toLocaleLowerCase();
  }, [errors]);

  return (
    <Tooltip arrow title={`File has been rejected: ${errorMessage}.`}>
      <ListItem divider secondaryAction={<ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />}>
        <Typography color="error">{attachment[0].file.name}</Typography>
      </ListItem>
    </Tooltip>
  );
};

const PaymentAttachments = ({ formik }: Props) => {
  const [rejectedFiles, setRejectedFiles] = useState([] as FileRejection[]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const attachments = formik.values.attachments ?? [];
      formik.setFieldValue('attachments', [...acceptedFiles, ...attachments]);

      if (fileRejections.length) {
        setRejectedFiles([fileRejections, ...rejectedFiles]);
      }
    },
    [formik, rejectedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 5 * 1048576,
    accept: {
      'text/txt': ['.txt'],
      'text/csv': ['.csv'],
      'text/html': ['.htm', '.html'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/tiff': ['.tif', '.tiff'],
      'application/pdf': ['.pdf'],
      'application/xml': ['.xml'],
      'application/rtf': ['.rtf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
      // 'application/*': ['.xml', '.pdf', '.rtf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
    }
  });

  const dragAndDropLabel = isDragActive ? 'Drop files here' : 'Drag and drop files here, or click to select files';

  return (
    <Stack>
      <InputLabel>
        <FormattedMessage id="attachments" />
      </InputLabel>
      <Box
        {...getRootProps()}
        border="1px dashed"
        borderColor={isDragActive ? 'black' : '#e6ebf1'}
        borderRadius={2}
        padding={3}
        minHeight={200}
        style={{ cursor: 'pointer', marginTop: 4 }}
        display="flex"
      >
        <input {...getInputProps()} />
        <Stack flex={1} display="flex" justifyContent="center">
          <Typography marginBottom={2} align="center">
            {dragAndDropLabel}
          </Typography>
          <Typography align="center" color="secondary">
            Max file size is 5MB. Supported files are .csv, .doc, .docx, .gif, .htm, .html, .jpeg, .jpg, .pdf, .png, .ppt, .pptx, .rtf,
            .tif, .tiff, .txt, .xml, .xls, .xlsx
          </Typography>
        </Stack>
      </Box>
      <Box>
        <List>
          {rejectedFiles?.map((attachment, index: number) => {
            return <RejectedPaymentAttachment key={index} attachment={attachment} />;
          })}
        </List>
      </Box>
      <FormikProvider value={formik}>
        <FieldArray name="attachments">
          {({ remove }) => {
            return (
              <List>
                {formik.values?.attachments?.map((attachment: any, index: number) => {
                  return <PaymentAttachment key={index} attachment={attachment} handleClick={() => remove(index)} />;
                })}
              </List>
            );
          }}
        </FieldArray>
      </FormikProvider>
    </Stack>
  );
};

export default PaymentAttachments;
