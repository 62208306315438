import { Box, Button, IconButton, List, ListItem, Menu, MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider } from 'formik';
import { LineItem } from 'types/payments';
import { NumericFormat } from 'react-number-format';
import { Typography } from '@mui/material';

type Props = {
  formik: any;
};

type LineItemProps = {
  formik: any;
  index: number;
  item: any;
  onRemove: () => void;
};

const LineItemField = ({ formik, item, index, onRemove }: LineItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const error = formik?.errors?.line_items?.[index];

  const hasNameError = Boolean(error?.name && formik.submitCount);
  const hasPriceError = Boolean(error?.price && formik.submitCount);

  return (
    <ListItem
      key={index}
      secondaryAction={
        <IconButton edge="end" aria-label="more" onClick={handleClick}>
          <EllipsisOutlined />
        </IconButton>
      }
    >
      <Stack direction="row" spacing={1} flex={1}>
        <TextField
          error={hasNameError}
          label={(hasNameError && error?.name) || undefined}
          fullWidth
          placeholder="Name"
          name={`line_items.${index}.name`}
          value={item?.name || ''}
          onChange={formik.handleChange}
        />
        <NumericFormat
          customInput={TextField}
          error={hasPriceError}
          label={(hasPriceError && error?.price) || undefined}
          variant="outlined"
          thousandSeparator
          placeholder="Price"
          value={item?.price || ''}
          decimalScale={2}
          onValueChange={(value) => {
            formik.setFieldValue(`line_items.${index}.price`, value.floatValue);
          }}
        />
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={onRemove}>Remove</MenuItem>
      </Menu>
    </ListItem>
  );
};

export const PaymentLineItems = ({ formik }: Props) => {
  const lineItemsError = typeof formik?.errors?.line_items === 'string';
  const hasLineItems = Boolean(formik.values.line_items.length);

  return (
    <Box flex={1} style={{ paddingBottom: 10, marginTop: hasLineItems ? 0 : 10 }}>
      <FormikProvider value={formik}>
        <FieldArray name="line_items">
          {({ push, remove, form }) => {
            return (
              <Stack padding={0} margin={0}>
                {hasLineItems && (
                  <List>
                    <div>
                      {formik.values.line_items.map((item: LineItem, index: number) => {
                        return <LineItemField item={item} key={index} formik={formik} index={index} onRemove={() => remove(index)} />;
                      })}
                    </div>
                  </List>
                )}
                {lineItemsError && (
                  <Box>
                    <Typography color="error" align="center" paddingBottom={2}>
                      {formik?.errors?.line_items}
                    </Typography>
                  </Box>
                )}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  marginLeft={{ xs: 0, sm: 2 }}
                  marginRight={{ xs: 0, sm: 2 }}
                >
                  {Boolean(formik.values.line_items.length) ? (
                    <Button variant="text" onClick={() => form.setFieldValue('line_items', [])}>
                      Clear all line items
                    </Button>
                  ) : (
                    <Typography align="center">Use Line Items for more granular accounting</Typography>
                  )}
                  <Button variant="contained" color="secondary" onClick={() => push({ name: '', price: undefined })}>
                    Add Line Item
                  </Button>
                </Stack>
              </Stack>
            );
          }}
        </FieldArray>
      </FormikProvider>
    </Box>
  );
};
