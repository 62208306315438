import { FormHelperText, InputLabel } from '@mui/material';

import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';

export type Props = {
  formik: any;
  children: ReactElement | undefined;
  fieldProperty: string;
  fieldLabelId: string;
};

const LabeledFormikInput = ({ formik, children, fieldProperty, fieldLabelId }: Props) => {
  const intl = useIntl();

  return (
    <>
      <InputLabel>
        <FormattedMessage id={fieldLabelId} />
      </InputLabel>
      {children}
      <FormHelperText error={get(formik.touched, fieldProperty) && Boolean(get(formik.errors, fieldProperty))}>
        {get(formik.touched, fieldProperty) && Boolean(get(formik.errors, fieldProperty)) ? (
          get(formik.errors, fieldProperty)
        ) : (
          <FormattedMessage id="please-enter" values={{ fieldName: intl.formatMessage({ id: fieldLabelId }).toLowerCase() }} />
        )}
      </FormHelperText>
    </>
  );
};

export default LabeledFormikInput;
