import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import LabeledFormikInput from './LabeledFormikInput';

interface RadioGroupProps<T> {
  formik: any;
  fieldProperty: string;
  fieldLabelId: string;
  options: T[];
}

const LabeledFormikRadioGroup = <T extends string | number>({ fieldProperty, fieldLabelId, formik, options }: RadioGroupProps<T>) => {
  return (
    <LabeledFormikInput formik={formik} fieldProperty={fieldProperty} fieldLabelId={fieldLabelId}>
      <RadioGroup name={fieldProperty} value={formik.values[fieldProperty] || ''} onChange={formik.handleChange} row>
        {options.map((option) => (
          <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
        ))}
      </RadioGroup>
    </LabeledFormikInput>
  );
};

export default LabeledFormikRadioGroup;
