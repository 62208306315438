import MainCard from 'components/MainCard';
import { Grid } from '@mui/material';
import { createUpdatePaymentMutation, fetchPayment, QUERY_KEY_PAYMENTS } from 'query/payments';
import { FormattedMessage } from 'react-intl';
import { PaymentForm } from 'components/organization/PaymentForm';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';

const EditPayment = () => {
  let { id } = useParams();

  const { data: payment } = useQuery([QUERY_KEY_PAYMENTS, { id }], () => fetchPayment(id));

  if (!payment) return null;

  const initialValues = {
    ...payment,
    amount_currency: payment.currency
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MainCard title={<FormattedMessage id="edit-payment" />}>
          <PaymentForm
            initialValues={initialValues}
            mutationFn={createUpdatePaymentMutation(id)}
            successUrl={`/payments/${id}`}
            cancelUrl={`/payments/${id}`}
            submitButtonText={<FormattedMessage id="edit-payment" />}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default EditPayment;
