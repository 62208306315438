import useAuth from 'hooks/useAuth';
import NavItem from './NavItem';
import { NavItemType } from 'types/menu';

export type Props = {
  menuItem: NavItemType;
};

export const RoleAwareNavItem = ({ menuItem }: Props) => {
  const { user } = useAuth();

  if (!menuItem.allowedRoles?.length || (user && menuItem.allowedRoles?.includes(user.role))) {
    return <NavItem item={menuItem} level={1} />;
  }

  return null;
};
