import { TextField } from '@mui/material';
import LabeledFormikInput from './LabeledFormikInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DatePickerFieldProps {
  formik: any;
  fieldProperty: string;
  fieldLabelId: string;
  onChange: (value: string | null) => any;
}

const LabeledFormikDatePicker = ({ onChange, fieldProperty, fieldLabelId, formik }: DatePickerFieldProps) => {
  return (
    <LabeledFormikInput formik={formik} fieldProperty={fieldProperty} fieldLabelId={fieldLabelId}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat="YYYY-MM-DD"
          renderInput={(params) => <TextField {...params} error={false} />}
          value={formik.values[fieldProperty] || ''}
          onChange={(value) => onChange(value)}
        />
      </LocalizationProvider>
    </LabeledFormikInput>
  );
};

export default LabeledFormikDatePicker;
