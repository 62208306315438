import { Stack, Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Theme } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { fetchCompany, fetchVendor, QUERY_KEY_COMPANIES, QUERY_KEY_VENDORS } from 'query/payments';
import { getCountryName } from 'utils/countries';

type Props = {
  formik: any;
};

const DetailsStep = ({ formik }: Props) => {
  const matchDownMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { data: vendor } = useQuery([QUERY_KEY_VENDORS, { id: formik.values.vendor }], () => fetchVendor(formik.values.vendor), {});

  const companyId = formik.values.company ?? undefined;

  const { data: company } = useQuery([QUERY_KEY_COMPANIES, { id: companyId }], () => fetchCompany(companyId), {
    enabled: companyId !== undefined
  });

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} lg={12}>
        <List sx={{ py: 0 }}>
          <ListItem divider={!matchDownMD}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={0.5}>
                  <Typography color="secondary" variant="h4">
                    <FormattedMessage id="account" />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider={!matchDownMD}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="name" />
                  </Typography>
                  <Typography>{formik.values.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="description" />
                  </Typography>
                  <Typography>{formik.values.description}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider={!matchDownMD}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="vendor" />
                  </Typography>
                  <Typography>{vendor?.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="currency" />
                  </Typography>
                  <Typography>{formik.values.currency}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider={!matchDownMD}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="iban" />
                  </Typography>
                  <Typography>{formik.values.iban}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography color="secondary">
                    <FormattedMessage id="account-number" />
                  </Typography>
                  <Typography>{formik.values.account_number}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          {company && (
            <>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary" variant="h4">
                        <FormattedMessage id="company" />
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="short-name" />
                      </Typography>
                      <Typography>{company.short_name}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="legal-name" />
                      </Typography>
                      <Typography>{company.legal_name}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="company-id" />
                      </Typography>
                      <Typography>{company.company_id}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="tax-id" />
                      </Typography>
                      <Typography>{company.tax_id}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="address" />
                      </Typography>
                      <Typography>{`${company.address_line_1} ${company.address_line_2}, ${company.postal_code} ${company.city},
                             ${getCountryName(company.country)}`}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="email" />
                      </Typography>
                      <Typography>{company.email}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </>
          )}

          {formik.values.counterparty && (
            <>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary" variant="h4">
                        <FormattedMessage id="counterparty" />
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="short-name" />
                      </Typography>
                      <Typography>{formik.values.counterparty.short_name}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="legal-name" />
                      </Typography>
                      <Typography>{formik.values.counterparty.legal_name}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="address-line-1" />
                      </Typography>
                      <Typography>{formik.values.counterparty.address_line_1 ?? '-'}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="address-line-1" />
                      </Typography>
                      <Typography>{formik.values.counterparty.address_line_2}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="postal-code" />
                      </Typography>
                      <Typography>{formik.values.counterparty.postal_code}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="city" />
                      </Typography>
                      <Typography>{formik.values.counterparty.city}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="country" />
                      </Typography>
                      <Typography>{formik.values.counterparty.country ?? '-'}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        <FormattedMessage id="email" />
                      </Typography>
                      <Typography>{formik.values.counterparty.email}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default DetailsStep;
