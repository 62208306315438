// types
import { DefaultConfigProps } from 'types/config';

export const drawerWidth = 260;
export const defaultPageSize = 50;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: '/dashboard',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'theme2',
  themeDirection: 'ltr'
};

export default config;
