import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { RoleAwareRoute } from './RoleAwareRoute';
import { UserRole } from '../types/auth';
import EditPayment from 'pages/payments/editPayment';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const BankAccountDetails = Loadable(lazy(() => import('pages/accounts/bankAccountDetails')));
const BankAccountTransactions = Loadable(lazy(() => import('pages/accounts/bankAccountTransactions')));
const AddBankAccount = Loadable(lazy(() => import('pages/accounts/addBankAccount')));
const EditBankAccount = Loadable(lazy(() => import('pages/accounts/editBankAccount')));
const UserList = Loadable(lazy(() => import('pages/users/userList')));
const UserDetails = Loadable(lazy(() => import('pages/users/userDetails')));
const AddUser = Loadable(lazy(() => import('pages/users/addUser')));
const EditUser = Loadable(lazy(() => import('pages/users/editUser')));
const CompanyList = Loadable(lazy(() => import('pages/companies/companyList')));
const CompanyDetails = Loadable(lazy(() => import('pages/companies/companyDetails')));
const CompanyInfo = Loadable(lazy(() => import('pages/companies/companyInfo')));
const CompanyCounterparties = Loadable(lazy(() => import('pages/companies/companyCounterparties')));
const CompanyUsers = Loadable(lazy(() => import('pages/companies/companyUsers')));
const CompanyAccounts = Loadable(lazy(() => import('pages/companies/companyAccounts')));
const EditCompany = Loadable(lazy(() => import('pages/companies/editCompany')));
const AddCompany = Loadable(lazy(() => import('pages/companies/addCompany')));
const CounterpartyList = Loadable(lazy(() => import('pages/counterparties/counterpartyList')));
const ConnectionList = Loadable(lazy(() => import('pages/connections/connectionList')));
const ConnectionDetails = Loadable(lazy(() => import('pages/connections/connectionDetails')));
const CounterpartyDetails = Loadable(lazy(() => import('pages/counterparties/counterpartyDetails')));
const CounterpartyInfo = Loadable(lazy(() => import('pages/counterparties/counterpartyInfo')));
const CounterpartyAccounts = Loadable(lazy(() => import('pages/counterparties/counterpartyAccounts')));
const EditCounterparty = Loadable(lazy(() => import('pages/counterparties/editCounterparty')));
const AddCounterparty = Loadable(lazy(() => import('pages/counterparties/addCounterparty')));

const AccountProfile = Loadable(lazy(() => import('pages/profile/account')));
const AccountTabPassword = Loadable(lazy(() => import('sections/profile/TabPassword')));

const Stats = Loadable(lazy(() => import('pages/dashboard/stats-page')));
const AssetList = Loadable(lazy(() => import('pages/assets/assetList')));
const Cash = Loadable(lazy(() => import('pages/assets/cash')));
const AssetDetails = Loadable(lazy(() => import('pages/assets/assetDetails')));

const PaymentList = Loadable(lazy(() => import('pages/payments/paymentList')));
const PaymentDetails = Loadable(lazy(() => import('pages/payments/paymentDetails')));
const AddPayment = Loadable(lazy(() => import('pages/payments/addPayment')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
              <Stats />
            </RoleAwareRoute>
          )
        },
        {
          path: 'assets',
          children: [
            {
              path: '',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <AssetList />
                </RoleAwareRoute>
              ),
              children: [
                {
                  path: '',
                  element: <Cash />
                },
                {
                  path: 'cash',
                  element: <Cash />
                }
              ]
            },
            {
              path: ':id',
              element: <AssetDetails />
            }
          ]
        },
        {
          path: 'payments',
          children: [
            {
              path: '',
              element: <PaymentList />
            },
            {
              path: ':id',
              element: <PaymentDetails />
            },
            {
              path: 'add',
              element: <AddPayment />
            },
            {
              path: ':id/edit',
              element: <EditPayment />
            }
          ]
        },

        {
          path: 'users',
          children: [
            {
              path: '',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager]}>
                  <UserList />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager]}>
                  <UserDetails />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id/edit',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager]}>
                  <EditUser />
                </RoleAwareRoute>
              )
            },
            {
              path: 'add',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager]}>
                  <AddUser />
                </RoleAwareRoute>
              )
            }
          ]
        },
        {
          path: 'companies',
          children: [
            {
              path: '',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <CompanyList />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id/edit',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <EditCompany />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <CompanyDetails />
                </RoleAwareRoute>
              ),
              children: [
                {
                  path: '',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CompanyInfo />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'info',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CompanyInfo />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'counterparties',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CompanyCounterparties />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'users',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager]}>
                      <CompanyUsers />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'bank_accounts',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CompanyAccounts />
                    </RoleAwareRoute>
                  )
                }
              ]
            },
            {
              path: 'add',
              element: <AddCompany />
            }
          ]
        },
        {
          path: 'counterparties',
          children: [
            {
              path: '',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <CounterpartyList />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <CounterpartyDetails />
                </RoleAwareRoute>
              ),
              children: [
                {
                  path: '',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CounterpartyInfo />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'info',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CounterpartyInfo />
                    </RoleAwareRoute>
                  )
                },
                {
                  path: 'bank_accounts',
                  element: (
                    <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                      <CounterpartyAccounts />
                    </RoleAwareRoute>
                  )
                }
              ]
            },
            {
              path: ':id/edit',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <EditCounterparty />
                </RoleAwareRoute>
              )
            },
            {
              path: 'add',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentManager, UserRole.FinancialDepartmentEmployee]}>
                  <AddCounterparty />
                </RoleAwareRoute>
              )
            }
          ]
        },
        {
          path: 'accounts',
          children: [
            {
              path: ':id',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentEmployee, UserRole.FinancialDepartmentManager]}>
                  <BankAccountDetails />
                </RoleAwareRoute>
              )
            },
            {
              path: 'add',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentEmployee, UserRole.FinancialDepartmentManager]}>
                  <AddBankAccount />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id/edit',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentEmployee, UserRole.FinancialDepartmentManager]}>
                  <EditBankAccount />
                </RoleAwareRoute>
              )
            },
            {
              path: ':id/transactions',
              element: (
                <RoleAwareRoute allowedRoles={[UserRole.FinancialDepartmentEmployee, UserRole.FinancialDepartmentManager]}>
                  <BankAccountTransactions />
                </RoleAwareRoute>
              )
            }
          ]
        },
        {
          path: 'profile',
          element: <AccountProfile />,
          children: [
            {
              path: 'password',
              element: <AccountTabPassword />
            }
          ]
        },
        {
          path: 'connections',
          children: [
            {
              path: '',
              element: <ConnectionList />
            },
            {
              path: ':id',
              element: <ConnectionDetails />
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
