import { Autocomplete, TextField } from '@mui/material';
import { Country } from '../../types/payments';
import _ from 'lodash';
import { useMemo } from 'react';
import { getAllCountries } from '../../utils/countries';
import { FormattedMessage } from 'react-intl';

type Props = {
  defaultValue?: string | null;
  onChange: (value: Country | null) => any;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
  labeled?: boolean;
};

const CountryAutocomplete = ({ defaultValue, onChange, size = 'medium', fullWidth = false, labeled = false }: Props) => {
  const countries = useMemo(getAllCountries, []);

  return (
    <Autocomplete
      disablePortal
      options={countries}
      defaultValue={_.find(countries, (country) => country.alpha2 === defaultValue) || null}
      renderInput={(params) => <TextField {...params} label={labeled ? <FormattedMessage id="country" /> : ''} />}
      getOptionLabel={(option: Country) => `${option.alpha3} (${option.name})`}
      onChange={(e, value) => onChange(value)}
      size={size}
      fullWidth={fullWidth}
    />
  );
};

export default CountryAutocomplete;
